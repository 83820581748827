import React from 'react';
import ProductCard from '../components/ProductCard/ProductCard'
import productData from '../data/productData'

const Products = () => {
  return (
    <div className="container">
      <div className="row">
        {productData.map((product) => (
          <div className="col-md-3" key={product.id}>
            <ProductCard product={product} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Products;
